import { Observable, of, throwError } from 'rxjs';
import { clearObjEmptyParams, getBaseUrl, getDeviceIdObservable } from '../utils/helper';
import { sign } from '../utils/signature';
import { catchError, debounceTime, mergeMap, pluck, tap } from 'rxjs/operators';
import { AjaxRequest, ajax } from 'rxjs/ajax';
import { storageService } from './storage.service';
import { message } from 'antd';

class HttpService {
    normalize(option: any) {
        //AjaxRequest
        let uid = storageService.getItem('uid') || '';
        if (!uid && window.location.href.indexOf('/login') == -1) {
            //其它页面未登录自动跳转登录页
            window.open('/', '_self');
        }
        if (option.url.includes('?')) {
            const parts = option.url.split('?');
            parts[1] = parts[1]
                .split('&')
                .filter((paramPair: any) => {
                    const pair = paramPair.split('=');
                    return pair[1] && pair[1] !== 'undefined' && pair[1] !== 'null';
                })
                .join('&');
            option.url = parts.join('?');
        }

        if (option.body) {
            option.body = clearObjEmptyParams(option.body);
        }

        if (option.headers) {
            option.headers = clearObjEmptyParams(option.headers);
        }
        return option;
    }

    // @ts-ignore
    ajax<R>(option: any): Observable<R> {
        //AjaxRequest

        return getDeviceIdObservable.pipe(
            mergeMap((deviceId) => {
                const request: AjaxRequest = {
                    crossDomain: true,
                    responseType: 'json',
                    timeout: 30000,
                };

                // option.url = '/gw' + option.url;
                option.url = getBaseUrl() + option.url; //华为CDN

                if (!option.headers) {
                    option.headers = { 'Content-Type': 'application/json; charset=UTF-8' };
                }
                if (!option.headers['Content-Type']) {
                    option.headers['Content-Type'] = 'application/json; charset=UTF-8';
                }
                option = this.normalize(option);

                option.headers = {
                    deviceId: deviceId,
                    ...sign(option.url, option.body, option.method, option.headers['Content-Type']),
                    ...option.headers,
                };

                return ajax(Object.assign(request, option)).pipe(
                    // retry(1),
                    catchError((error) => {
                        console.error('error: ', error);
                        // throttleError();
                        return throwError(error);
                    }),
                    pluck('response'),
                    tap((res) => {
                        console.log(res);
                        // 112002 TOKEN信息不存在
                        // 112003 TOKEN信息无效
                        // 401 TOKEN为空
                        // 9527 100504 账户被禁用

                        // if (/112002|112003|401|9527/.test(String(res?.code))) {
                        //   window.localStorage.clear();
                        //   // userService.resetUser();
                        // }
                        if (res.code !== 200 && res.msg) {
                            message.warning(res.msg);
                        }
                        if (/100504|9527/.test(String(res?.code))) {
                            if (window.location.pathname !== '/') {
                                setTimeout(function () {
                                    window.location.href = '/';
                                }, 3000);
                            }
                        }
                        if (/9530/.test(String(res?.code))) {
                            setTimeout(function () {
                                storageService.removeItem('user');
                                storageService.removeItem('token');
                                storageService.removeItem('uid');
                                storageService.removeItem('agentName');
                                window.location.href = '/';
                            }, 3000);
                        }
                    })
                );
            })
        );
    }
}

export const httpService = new HttpService();
