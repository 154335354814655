/**
 * Created by hao.cheng on 2017/4/13.
 */
import React, { useEffect, useState } from 'react';
import { Menu, Layout,Popconfirm} from 'antd';
import { useHistory } from 'react-router-dom';
import { storageService } from '../modules/common/services/storage.service';
import defaultPeople from '../assets/touxiang-big-02@2x.png'

const { Header } = Layout;

type HeaderCustomProps = {
    toggle: () => void;
    collapsed: boolean;
    user: any;
    responsive?: any;
    path?: string;
};
let Inituser={
    headImg: defaultPeople,
    inviteUrl:'',
    isRes: 0,
    mobile: '',
    nickName: '',
    ticket: "",
    token:'',
    uid: 0,
    userLevel: 0,
    userName: null}
const HeaderCustom = (props: HeaderCustomProps) => {
    const [user, setUser] = useState<any>(Inituser);
    const history = useHistory();

    useEffect(() => {
        let storageuser = storageService.getItem('user');
         setUser(storageuser?JSON.parse(storageuser):Inituser);
    }, []);
    const logout = () => {
        storageService.clear()
        history.push('/login');
    };

    return (
        <Header className="custom-theme header">
            <div className='header-img-box'>
                <img src={user.headImg || defaultPeople} alt="" />
            </div>
            <div className='header-nickname'>
                {user.nickName?<Popconfirm
                    title='退出登录？'
                    onConfirm={logout}
                    okText='确定'
                    cancelText='取消'
                    trigger='hover'
                >
                    <span>{user.agentName}</span>
                </Popconfirm>
                :<span onClick={ logout} style={{color:'blue',cursor:'pointer'}}>请登录</span>
                }
            </div>

        </Header>
    );
};

export default HeaderCustom;
