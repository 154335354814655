import qs from 'qs';
import md5 from 'blueimp-md5';
// @ts-ignore
import sha1 from 'js-sha1';
import {
    cloneDeep,
    divide,
    filter,
    floor,
    forOwn,
    isArray,
    isEmpty,
    isNaN,
    isNil,
    isNull,
    isObject,
    isString,
    isUndefined,
    keys,
    map,
    pull,
    throttle,
    trim,
    zipObject,
} from 'lodash-es';
import { HttpRequestHeader } from 'antd/lib/upload/interface';
const BaseConfig = Object.freeze({
    version: '1.4.1',
    clientType: 'web',
    signKey: '3577a5ec93594b7ac2c615333e16256r',
    successCode: '6000',
    loginOutCode: '6001',
    forbiddenCode: '6003',
    dynamicErrCode: '6012',
    nameSpace: {
        matchFilter: 'matchFilter',
        user: 'user',
        home: 'home',
    },
    FiveMajorMatch: ['英超', '意甲', '西甲', '德甲', '法甲'], // 五大赛事
    OBSHost: 'https://sta-fe.pal6s2.com',
});

export function sign(
    url: string,
    data: any,
    method: string,
    contentType: string
): HttpRequestHeader {
    const timestamp = Date.now();
    let signStr = `key=${BaseConfig.signKey}&t=${timestamp}`;
    const signStr2 = obj2str(url, normalizeData(url, data, method), method, contentType);
    if (signStr2) {
        signStr = signStr2 + '&' + signStr;
    }

    let token = localStorage.getItem('token');
    return {
        version: BaseConfig.version,
        'client-type': 'web',
        sign: sha1(md5(signStr)),
        t: timestamp + '',
        r: createNonceStr(36),
        platform: 'dq',
        authorization: token ? `Bearer ${token}` : 'Basic YXBwOmFwcA==',
    };
}

function obj2str(url: string, data: any, method: string, contentType: string) {
    if (isNil(data)) return '';
    if (
        method.toLocaleUpperCase() === 'GET' ||
        (typeof contentType === 'string' &&
            contentType.indexOf('application/x-www-form-urlencoded') > -1)
    ) {
        const keys = Object.keys(data).sort();
        return keys.map((key) => `${key}=${data[key]}`).join('&');
    } else {
        if (!Object.keys(data).length) return '';
        return JSON.stringify(data);
    }
}

function normalizeData(url: string, data: any, method: any) {
    let tempData = cloneDeep(data);
    const splitUrl = url.split('?');
    const queryParams = splitUrl[1];

    if (queryParams) {
        tempData = new URLSearchParams(queryParams).entries();
        tempData = paramsToObject(tempData);
    }

    /**post请求需要将接口参数带入计算 */
    if (method.toLocaleUpperCase() !== 'GET' && data) {
        return data;
    }

    return clearObjEmptyParams(tempData);
}

function createNonceStr(len: number) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    const uuid = [];
    const radix = chars.length;
    if (len) for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
    return uuid.join('');
}
function clearObjEmptyParams(obj: any) {
    return (function prune(current) {
        forOwn(current, function (value, key) {
            if (
                isUndefined(value) ||
                isNull(value) ||
                isNaN(value) ||
                (isString(value) && isEmpty(value)) ||
                (isObject(value) && isEmpty(prune(value)))
            ) {
                delete current[key];
            }
        });

        if (isArray(current)) pull(current, undefined);

        return current;
    })(cloneDeep(obj));
}

function paramsToObject(entries: any) {
    const result: any = {};
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
}
